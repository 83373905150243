/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.img-logo {
  width: 72px;
  margin-right: 8px;
}
.login-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 2.5em;
  font-weight: 600;
  color: #D22027;
  margin: 32px 0;
}
.mobile .login-title {
  font-size: 1.5em;
}
.login-title-second {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: #D22027;
  margin: 32px 0 24px;
}
.mobile .login-title-second {
  font-size: 1em;
}
.login-box {
  margin: 0 auto;
  max-width: 400px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.login-box .content {
  padding: 32px 32px 24px;
  text-align: left;
}
.mobile .login-box .content {
  padding: 24px 16px 16px;
}
.row-forgot {
  margin-top: -12px;
  margin-bottom: 4px;
  text-align: right;
}
.link-forgot {
  font-size: 0.9em;
  color: #499c91;
}
.row-or {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #8c8c8c;
}
.ant-btn.line-login-btn {
  background-color: #00C300;
  color: #fff;
  border: none;
  padding: 0;
  position: relative;
  line-height: 40px;
}
.ant-btn.line-login-btn:hover,
.ant-btn.line-login-btn:focus {
  background-color: #00c900;
}
.ant-btn.line-login-btn:hover .logo,
.ant-btn.line-login-btn:focus .logo {
  border-right-color: #00e000;
}
.ant-btn.line-login-btn .logo {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 4px;
  top: 0;
  left: 4px;
  border-right: 1px solid #00b300;
}
.ant-btn.line-login-btn .label {
  font-family: Helvetica, Arial, sans-serif;
  padding-left: 44px;
}
.mobile .ant-btn.line-login-btn {
  line-height: 32px;
}
.mobile .ant-btn.line-login-btn .logo {
  width: 32px;
  height: 32px;
  left: 2px;
}
.mobile .ant-btn.line-login-btn .label {
  padding-left: 34px;
}
